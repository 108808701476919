<template lang="">
  <div>
    <div class="topbar d-flex align-items-center">
      <div class="container d-flex justify-content-center justify-content-md-between">
        <div class="contact-info d-flex align-items-center">
          <i class="bi bi-envelope d-flex align-items-center"
            ><a href="mailto:contact@helathel.com">contact@helathel.com</a></i
          >
          <i class="bi bi-phone d-flex align-items-center ms-4">
            <a href="tel:+94765903122" class="d-flex align-items-center text-decoration-none">
              <span>+94 765903122</span>
            </a>
          </i>
        </div>
        <div class="social-links d-none d-md-flex align-items-center">
          <a href="#" class="twitter"><i class="bi bi-twitter-x"></i></a>
          <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
          <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
          <a href="https://linkedin.com/company/arm-private-limited" class="linkedin" target="_blank"><i class="bi bi-linkedin"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LayoutHeader",
};
</script>
<style lang=""></style>
